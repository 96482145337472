/**
 * Created by shivaraman on 5/10/17.
 */
import {REMOVE_PERSONS} from '../actions/action_constants';

export function reduceForRemoval(state = [], action) {
    switch (action.type) {
        case REMOVE_PERSONS:
            return action.payload.data; // data is an axios specific. Whatever response we get is in data under payload or whatever is returned in action
    }
    return state;
}
