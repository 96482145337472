/**
 * Created by pshivaraman on 4/30/18.
 */

import {FETCH_DOCUMENT, FETCH_DOCUMENTS, FETCH_DOCUMENTS_BY_IDS, SEARCH_DOCUMENTS} from "../actions/action_constants";

export function reduceDocs(state = [], action) {
    switch (action.type) {
        case FETCH_DOCUMENTS:
            console.log('Payload is ', action.payload)
        case FETCH_DOCUMENTS_BY_IDS:
            return action.payload.data;
    }
    return state;
}

export function reduceDoc(state = null, action) {
    switch (action.type) {
        case FETCH_DOCUMENT:
            return action.payload.data;
    }
    return state;
}

export function reduceDocResults(state = [], action){
    switch (action.type){
        case SEARCH_DOCUMENTS:
            return action.payload.data;
    }
    return state;
}