import {FETCH_MANAGED_ORGS} from "../actions/action_constants";
import {getOrgObj} from "../actions/org_actions";

export function reduceManagedOrgs(state = [], action) {
    switch (action.type) {
        case FETCH_MANAGED_ORGS:
            return action.payload.data.map(getOrgObj); // data is an axios specific. Whatever response we get is in data under payload or whatever is returned in action
    }
    return state;
}

