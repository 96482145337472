/**
 * Created by shivaraman on 5/10/17.
 */
import {
    DELETE_SMO_DOCUMENT,
    FETCH_ORG_FACILITY,
    FETCH_SMO_DOCUMENTS,
    FETCH_SMOS,
    FETCH_SMOS_BY_ORG,
    INVALID_TOKEN,
    LOGOUT_SUCCESS,
    SEARCH_FACILITIES,
    UPLOAD_SMO_DOCUMENTS
} from "../actions/action_constants";
import {getFacilityObj} from "../actions/facility_actions";

export function reduceFacilities(state = [], action) {
    switch (action.type) {
        case FETCH_SMOS:
        case FETCH_SMOS_BY_ORG:
            return action.payload.data.map(getFacilityObj); // data is an axios specific. Whatever response we get is in data under payload or whatever is returned in action
        case INVALID_TOKEN:
            return state;
        case LOGOUT_SUCCESS:
            state = undefined;
            return state;
    }
    return state;
}

export function reduceOrgFacility(state = [], action) {
    switch (action.type) {
        case FETCH_ORG_FACILITY:
            return getFacilityObj(action.payload.data);
    }
    return state;
}

export function reduceFacilitySearchResults(state = [], action) {
    switch (action.type) {
        case SEARCH_FACILITIES:
            return getFacilityObj(action.payload.data);
    }
    return state;
}

export function reduceSmoDocuments(state = [], action) {
    switch (action.type) {
        case FETCH_SMO_DOCUMENTS:
            return action.payload.data;
        case UPLOAD_SMO_DOCUMENTS:
            return action.payload.data.documents;
        case DELETE_SMO_DOCUMENT:
            return action.payload.data.documents;
    }
    return state;
}