import {FETCH_ORG_TASKS, FETCH_TASK, FETCH_TASKS} from "../actions/action_constants";

/**
 * Created by pshivaraman on 3/23/18.
 */

export function reduceTasks(state = null, action) {
    switch (action.type) {
        case FETCH_ORG_TASKS:
        case FETCH_TASKS:
            return action.payload.data;
    }
    return state;
}

export function reduceTask(state = null, action){
    switch (action.type){
        case FETCH_TASK:
            return action.payload.data;
    }
    return state;
}