/**
 * Created by pshivaraman on 2/14/19.
 */

import {
    CREATE_ORG_CONTRACT,
    FETCH_ORG_CONTRACT,
    FETCH_ORG_CONTRACTS,
    UPDATE_ORG_CONTRACT
} from "../actions/action_constants";
import {getContractObj} from "../actions/contract_actions";

export function reduceOrgContracts(state = [], action) {
    switch (action.type) {
        case FETCH_ORG_CONTRACTS:
            return action.payload.data.map(getContractObj);
        case CREATE_ORG_CONTRACT:
            if (state.contracts) {
                state.contracts.concat(getContractObj(action.payload.data));
                return state.contracts;
            }
            return [getContractObj(action.payload.data)];
        case UPDATE_ORG_CONTRACT:
            // iterate thru contracts then remove the one updated or replace it with the new one
            let newContracts = [];
            if (state.contracts) {
                state.contracts.forEach((contract) => {
                    if (contract.contractId === action.payload.data.contractId) {
                        newContracts.concat(getContractObj(action.payload.data))
                    } else {
                        newContracts.concat(contract)
                    }
                })
            } else {
                newContracts.concat(getContractObj(action.payload.data))
            }
            return newContracts;
    }
    return state;
}

export function reduceOrgContract(state = [], action) {
    switch (action.type) {
        case FETCH_ORG_CONTRACT:
            return getContractObj(action.payload.data);
    }
    return state;
}