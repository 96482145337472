/**
 * Created by pshivaraman on 7/27/18.
 */

import {SHOW_MESSAGE} from "../actions/action_constants";

export function reduceMsgs(state = [], action) {
    switch (action.type) {
        case SHOW_MESSAGE:
            return action.payload;
    }
    return state;
}