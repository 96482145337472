import {BEGIN_BULK_DOC_UPLOAD, DOCS_BULK_UPLOADED} from "../actions/action_constants";

export function reduceDocsAndStatus(state = [], action) {
    switch (action.type) {
        case DOCS_BULK_UPLOADED:
            let docsAndStatus = [];
            docsAndStatus.push(...state);
            docsAndStatus.push(action.payload.data);
            return docsAndStatus;
            // return {
            //     docsAndStatus: docsAndStatus
            // };
        case BEGIN_BULK_DOC_UPLOAD:
            return [];
            // return {
            //     docsAndStatus: []
            // };
    }
    return state;
}

