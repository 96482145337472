import {CSV_UPLOAD_ASSETS, CSV_UPLOAD_DOC_CONTROLS} from '../actions/action_constants';

export function reduceAssets(state = [], action) {
    switch (action.type) {
        case CSV_UPLOAD_ASSETS:
            return action.payload.data; // data is an axios specific. Whatever response we get is in data under payload or whatever is returned in action
    }
    return state;
}

export function reduceDocControls(state = [], action) {
    switch (action.type) {
        case CSV_UPLOAD_DOC_CONTROLS:
            return action.payload.data; // data is an axios specific. Whatever response we get is in data under payload or whatever is returned in action
    }
    return state;
}
