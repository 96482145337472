/**
 * Created by shivaraman on 8/10/17.
 */

import {FETCH_AGENCIES} from "../actions/action_constants";

export function reduceAgencies(state = [], action){
    switch(action.type){
        case FETCH_AGENCIES:
            return action.payload.data;
    }
    return state;
}