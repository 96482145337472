/**
 * Created by shivaraman on 5/28/2020.
 */
import {FETCH_ENTITIES_FOR_COMPLIANCE} from "../actions/action_constants";

export function reduceCompliantEntities(state = {all: [], compliant: [], nonCompliant: []}, action) {
    switch (action.type) {
        case FETCH_ENTITIES_FOR_COMPLIANCE:
            return action.payload
    }
    return state;
}
