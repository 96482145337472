/**
 * Created by shivaraman on 5/10/17.
 */
import {SELF_REPORTING_AUTH_DONE} from '../actions/action_constants';

export function reduceSubjectForSelfReporting(state = {}, action) {
    switch (action.type) {
        case SELF_REPORTING_AUTH_DONE:
            return action.payload;
    }
    return state;
}

