import {DateUtils} from "../actions/common_utils";


export class DD254Mapping {
    constructor(){

    }

    populateContract = (contract, mapObject) => {
        for (const [key, value] of Object.entries(this.fieldMapping)){
            // console.log('Value is ', value['method'](value.fieldName, mapObject, value.basedFieldNameOrMappedVal));
            contract[key] = value['method'](value.fieldName, mapObject, value.basedFieldNameOrMappedVal) // Dont care if it does not apply. JS can handle it
        }
    }
    getFieldVal = (fieldName, mapObject) => {
        return mapObject[fieldName];
    }

    getFieldValTrueOrFalse = (fieldName, mapObject) => {
        return mapObject[fieldName] === '1';
    }
    getFieldValBasedOn = (fieldName, mapObject, basedFieldNameOrMappedVal) => {
        if (mapObject[basedFieldNameOrMappedVal] === '1'){
            return mapObject[fieldName];
        }
    }
    getFieldValBasedOnWithMapping = (fieldName, mapObject, basedFieldNameOrMappedVal) => {
        if (mapObject[fieldName] === '1'){
            return basedFieldNameOrMappedVal[0];
        } else {
            return basedFieldNameOrMappedVal[1];
        }
    }

    getFieldValsFromCheckBoxes = (fieldName, mapObject, basedFieldNameOrMappedVal) => {
        let retArr = [];
        for (let {fieldName, mappedVal} of basedFieldNameOrMappedVal){
            if (mapObject[fieldName] === '1'){
                retArr.push(mappedVal);
            }
        }
        return retArr;
    }

    getFieldDateValBasedOn = (fieldName, mapObject, basedFieldNameOrMappedVal) => {
        if (mapObject[basedFieldNameOrMappedVal] === '1'){
            return DateUtils.getDate(mapObject[fieldName]);
        }
    }
    getFieldValsConcatenated = (fieldNames, mapObject) => {
        let retVal = '';
        for (let eachFieldName of fieldNames){
            if (retVal !== ''){
                retVal += ", ";
            }
            retVal += mapObject[eachFieldName];
        }
        return retVal;
    }

    fieldMapping = {
        "followsOn" : {
            "fieldName": "form1[0].page1[0].Block4[0].Yes[0]",
            "method": this.getFieldValTrueOrFalse,
        },
        "followsOnContractNo" : {
            "basedFieldNameOrMappedVal": "form1[0].page1[0].Block4[0].Yes[0]",
            "fieldName": "form1[0].page1[0].Block4[0].isTransferred[0]",
            "method": this.getFieldValBasedOn
        },
        "finalDD254" : {
            "fieldName": "form1[0].page1[0].Block5[0].Yes[0]",
            "method": this.getFieldValTrueOrFalse
        },
        "finalReqPeriod" : {
            "basedFieldNameOrMappedVal": "form1[0].page1[0].Block5[0].Yes[0]",
            "fieldName": "form1[0].page1[0].Block5[0].retention[0]",
            "method": this.getFieldValBasedOn
        },
        "finalReqDate" : {
            "basedFieldNameOrMappedVal": "form1[0].page1[0].Block5[0].Yes[0]",
            "fieldName": "form1[0].page1[0].Block5[0].ReqDated[0]",
            "method": this.getFieldDateValBasedOn
        },
        "contractor" : {
            "fieldName": [
                "form1[0].page1[0].Block6[0].NameCageCSO[0].Name[0]",
                "form1[0].page1[0].Block6[0].NameCageCSO[0].Cage[0]",
                "form1[0].page1[0].Block6[0].NameCageCSO[0].CSO[0]"
            ],
            "method": this.getFieldValsConcatenated
        },
        "subContractors" : {
            "fieldName": [
                "form1[0].page1[0].Block7[0].NameCageCSO[0].Name[0]",
                "form1[0].page1[0].Block7[0].NameCageCSO[0].Cage[0]",
                "form1[0].page1[0].Block7[0].NameCageCSO[0].CSO[0]"
            ],
            "method": this.getFieldValsConcatenated
        },
        "actualPerformance" : {
            "fieldName": [
                "form1[0].page1[0].Block8[0].LocationCageCSO[0].Location[0]",
                "form1[0].page1[0].Block8[0].LocationCageCSO[0].Cage[0]",
                "form1[0].page1[0].Block8[0].LocationCageCSO[0].CSO[0]"
            ],
            "method": this.getFieldValsConcatenated
        },
        "generalIdent" : {
            "fieldName": "form1[0].page1[0].Block9[0]",
            "method": this.getFieldVal
        },
        "releaseType":{
            "fieldName": "form1[0].page1[0].Block12[0].DirectThru[0].direct[0]",
            "method": this.getFieldValBasedOnWithMapping,
            "basedFieldNameOrMappedVal": ["public","through"]
        },
        "throughReleaseDesc": {
            "basedFieldNameOrMappedVal": "form1[0].page1[0].Block12[0].DirectThru[0].thru[0]",
            "fieldName": "form1[0].page1[0].Block12[0].DirectThru[0].specify[0]",
            "method": this.getFieldValBasedOn
        },
        "securityGuidance" : {
            "fieldName": "form1[0].page1[0].Block13[0].text[0]",
            "method" : this.getFieldVal
        },
        "addSecurity" : {
            "fieldName": "form1[0].page1[0].Block14[0].Yes[0]",
            "method": this.getFieldValTrueOrFalse
        },
        "addSecurityDesc" : {
            "basedFieldNameOrMappedVal": "form1[0].page1[0].Block14[0].Yes[0]",
            "fieldName": "form1[0].page1[0].Block14[0].identify[0]",
            "method": this.getFieldValBasedOn
        },
        "inspections" : {
            "fieldName": "form1[0].page1[0].Block15[0].Yes[0]",
            "method": this.getFieldValTrueOrFalse
        },
        "inspectionsDesc" : {
            "basedFieldNameOrMappedVal": "form1[0].page1[0].Block15[0].Yes[0]",
            "fieldName": "form1[0].page1[0].Block15[0].identify[0]",
            "method": this.getFieldValBasedOn
        },
        "gcaName" : {
            "fieldName": "form1[0].page1[0].Block16[0].ab[0].GCAName[0]",
            "method" : this.getFieldVal
        },
        "gcaAAC" : {
            "fieldName": "form1[0].page1[0].Block16[0].ab[0].AAC[0]",
            "method" : this.getFieldVal
        },
        "gcaAddress" : {
            "fieldName": "form1[0].page1[0].Block16[0].Address[0]",
            "method" : this.getFieldVal
        },
        "gcaPocName" : {
            "fieldName": "form1[0].page1[0].Block16[0].def[0].POCName[0]",
            "method" : this.getFieldVal
        },
        "gcaPocPhone" : {
            "fieldName": "form1[0].page1[0].Block16[0].def[0].Phone[0]",
            "method" : this.getFieldVal
        },
        "gcaEmailAddress" : {
            "fieldName": "form1[0].page1[0].Block16[0].def[0].Email[0]",
            "method" : this.getFieldVal
        },
        "certOfficialName" : {
            "fieldName": "form1[0].page1[0].Block17[0].abc[0].Name[0]",
            "method" : this.getFieldVal
        },
        "certOfficialTitle" : {
            "fieldName": "form1[0].page1[0].Block17[0].abc[0].Title[0]",
            "method" : this.getFieldVal
        },
        "certOfficialAddress" : {
            "fieldName": "form1[0].page1[0].Block17[0].abc[0].Address[0]",
            "method" : this.getFieldVal
        },
        "certOfficialPhone" : {
            "fieldName": "form1[0].page1[0].Block17[0].defg[0].Phone[0]",
            "method" : this.getFieldVal
        },
        "certCageCode" : {
            "fieldName": "form1[0].page1[0].Block17[0].defg[0].Cage[0]",
            "method" : this.getFieldVal
        },
        "certAAC" : {
            "fieldName": "form1[0].page1[0].Block17[0].defg[0].AAC[0]",
            "method" : this.getFieldVal
        },
        "certEmailAddress" : {
            "fieldName": "form1[0].page1[0].Block17[0].defg[0].Email[0]",
            "method" : this.getFieldVal
        },
        "distribution":{
            "method": this.getFieldValsFromCheckBoxes,
            "basedFieldNameOrMappedVal": [
                {
                    "fieldName": "form1[0].page1[0].Block18[0].Checklist[0].abcde[0].a[0]",
                    "mappedVal": "contractor"
                },
                {
                    "fieldName": "form1[0].page1[0].Block18[0].Checklist[0].abcde[0].b[0]",
                    "mappedVal": "sub-contractor"
                },
                {
                    "fieldName": "form1[0].page1[0].Block18[0].Checklist[0].abcde[0].c[0]",
                    "mappedVal": "smo"
                },
                {
                    "fieldName": "form1[0].page1[0].Block18[0].Checklist[0].abcde[0].d[0]",
                    "mappedVal": "overseas-admin"
                },
                {
                    "fieldName": "form1[0].page1[0].Block18[0].Checklist[0].abcde[0].e[0]",
                    "mappedVal": "admin-contact-officer"
                },
                {
                    "fieldName": "form1[0].page1[0].Block18[0].Checklist[0].fCheckbox[0].f[0]",
                    "mappedVal": "others"
                }
            ]
        },
        "accessReqd":{
            "method": this.getFieldValsFromCheckBoxes,
            "basedFieldNameOrMappedVal": [
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].a[0]",
                    "mappedVal": "com-sec-info"
                },
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].b[0]",
                    "mappedVal": "restricted-data"
                },
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].c[0]",
                    "mappedVal": "nuclear-design-info"
                },
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].d[0]",
                    "mappedVal": "former-restricted-data"
                },
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].eCheckboxBlock[0].SCICheckbox[0].SCI[0]",
                    "mappedVal": "sci"
                },
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].eCheckboxBlock[0].SCICheckbox[0].NonSCI[0]",
                    "mappedVal": "non-sci"
                },
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].jkCheckbox[0].j[0]",
                    "mappedVal": "cui-info"
                },
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].jkCheckbox[0].k[0]",
                    "mappedVal": "other"
                },
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].f[0]",
                    "mappedVal": "spec-access-info"
                },
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].g[0]",
                    "mappedVal": "nato-info"
                },
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].h[0]",
                    "mappedVal": "foreign-govt-info"
                },
                {
                    "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].i[0]",
                    "mappedVal": "accm-info"
                }
            ]
        },
        "otherAccessReqd" : {
            "basedFieldNameOrMappedVal": "form1[0].page1[0].Block10[0].Checklist[0].jkCheckbox[0].k[0]",
            "fieldName": "form1[0].page1[0].Block10[0].Checklist[0].jkCheckbox[0].other[0]",
            "method": this.getFieldValBasedOn
        },
        "otherDistrReqd" : {
            "basedFieldNameOrMappedVal": "form1[0].page1[0].Block18[0].Checklist[0].fCheckbox[0].f[0]",
            "fieldName": "form1[0].page1[0].Block18[0].Checklist[0].fCheckbox[0].other[0]",
            "method": this.getFieldValBasedOn
        },
        "contractorAccessReqd":{
            "method": this.getFieldValsFromCheckBoxes,
            "basedFieldNameOrMappedVal": [
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].abcdef[0].a[0]",
                    "mappedVal": "access-classified-info"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].abcdef[0].b[0]",
                    "mappedVal": "classified-docs-only"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].abcdef[0].c[0]",
                    "mappedVal": "recv-classified-material"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].abcdef[0].d[0]",
                    "mappedVal": "classified-hardware"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].abcdef[0].e[0]",
                    "mappedVal": "perf-svcs-only"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].abcdef[0].f[0]",
                    "mappedVal": "outside-classified-info"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].ghijklmCheckbox[0].g[0]",
                    "mappedVal": "use-dtic-svcs"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].ghijklmCheckbox[0].h[0]",
                    "mappedVal": "req-comsec-acct"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].ghijklmCheckbox[0].i[0]",
                    "mappedVal": "tempest-reqs"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].ghijklmCheckbox[0].j[0]",
                    "mappedVal": "opsec-reqs"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].ghijklmCheckbox[0].k[0]",
                    "mappedVal": "def-courier-svc"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].ghijklmCheckbox[0].l[0]",
                    "mappedVal": "rsg-cui"
                },
                {
                    "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].ghijklmCheckbox[0].mCheckbox[0].m[0]",
                    "mappedVal": "other"
                },

            ]
        },
        "contractorOtherAccessReqd":{
            "basedFieldNameOrMappedVal": "form1[0].page1[0].Block11[0].Checklist[0].ghijklmCheckbox[0].mCheckbox[0].m[0]",
            "fieldName": "form1[0].page1[0].Block11[0].Checklist[0].ghijklmCheckbox[0].mCheckbox[0].other[0]",
             "method": this.getFieldValBasedOn
        },
    };
}

