import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from "redux";
import thunk from 'redux-thunk';
import ReduxPromise from "redux-promise";
import reducers from "./reducers";
import {BrowserRouter, Route, Switch} from "react-router-dom";
// Babel
import "core-js";
import "regenerator-runtime/runtime";
// CSS
import 'jquery';
import 'bootstrap';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './assets/css/truvetting-master.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/v4-shims.css';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-picky/dist/picky.css';
import 'react-circular-progressbar/dist/styles.css';
// Main
import Main from "./containers/main";
import OrgList from "./containers/org/org_list"
import PageNotFound from './containers/page_not_found';
import Authenticate from "./containers/auth/authenticate";
import Auth from "./containers/auth/require_auth";
import ReportingAuth from "./containers/auth/require_auth_for_reporting";
import TruNav from "./components/navbar";
import Growler from "./components/growler";
import LoadingSpinner from "./components/loading_spinner";
// Org Dash
import OrgNew from './components/org/org_new';
import OrgDash from './containers/org/dash/org_dash';
import OrgDashIndex from './containers/org/dash/org_index';
import FacilityList from './containers/org/dash/facility/facility_list';
import DashFacilities from './containers/org/dash/dashboard/dash_facility_list';
import FacilityNew from './containers/org/dash/facility/facility_new';
import FacilityUpdate from './containers/org/dash/facility/facility_update';
import FacilityDetail from './containers/org/dash/facility/facility_detail';
import OrgDetail from './containers/org/org_detail';
import OrgUpdate from './containers/org/org_update';
import DocList from './containers/org/dash/doc/doc_list';
import ContractList from './containers/org/dash/contract/contract_list';
import ContractNew from './containers/org/dash/contract/contract_new';
import ContractUpdate from './containers/org/dash/contract/contract_update';
import ContractDetail from './containers/org/dash/contract/contract_detail';
import SubjectDetail from './containers/org/dash/subject/subject_detail';
import GenerateReport from './containers/org/dash/report/generate_report';
import SubjectList from './containers/org/dash/subject/subject_list';
import DashSubjects from './containers/org/dash/dashboard/dash_subject_list';
import SubjectUploadList from './containers/org/dash/subject/subject_upload_list';
import SubjectRemovalList from './containers/org/dash/subject/subject_removal_list';
import ServiceCenterDash from './containers/org/dash/serviceCenter/service_center';
import ServiceCenterOrgDetail from './containers/org/dash/serviceCenter/service_center_org_detail';
import OrgUserList from "./containers/org/dash/user/user_list";
import TaskList from './containers/org/dash/task/task_list';
import {AssetList} from './containers/org/dash/asset/asset_list';
import {AssetNew} from './containers/org/dash/asset/asset_new';
import {AssetUploadList} from './containers/org/dash/asset/asset_upload_list';
import VisitRequestList from './containers/org/dash/visit/visit_issues_list';
import TaskNew from './containers/org/dash/task/task_new';
import TaskDetail from './containers/org/dash/task/task_detail';
import ContactUsForm from './containers/unauth/contact_us_form';
// Sys Dash
import SysDash from './containers/sys/dash/sys_dash';
import SysDashIndex from './containers/sys/dash/sys_index';
import SysOrgList from './containers/sys/dash/org/org_list';
import SysOrgDetail from './containers/sys/dash/org/org_sys_detail';
import SysUserList from './containers/sys/dash/user/sys_user_list';
import SysSubjectMergeList from './containers/sys/dash/subject/sys_subject_merge_list';
import SysTaskList from './containers/sys/dash/task/task_list';
import SysTaskNew from './containers/sys/dash/task/task_new';
import SysTaskDetail from './containers/sys/dash/task/task_detail';
import PurchaseList from './containers/sys/dash/signaturePurchase/signature_purchase_list';

import {VisitList} from './containers/reporting/subject/visit/visit_list';

// Account
import UserProfile from './containers/account/user_profile';
// Reporting
import {ReportingSubjectMenu} from './containers/reporting/subject/reporting_subject_menu';
import {ReportEmailVerify} from './containers/reporting/subject/report_email_verify';
import {ReportBlackmail} from './containers/reporting/subject/foreignTravel/report_blackmail';
import {ReportSecondFactor} from './containers/reporting/subject/report_second_factor';
import ReportForeignTravel from './containers/reporting/subject/foreignTravel/report_foreign_travel_step';
import ReportForeignContacts from './containers/reporting/subject/foreignTravel/report_foreign_contacts';
import ReportForeignVoting from './containers/reporting/subject/foreignTravel/report_foreign_voting';
import ReportPersonalInfoAddress from './containers/reporting/subject/personalInfo/report_personalInfo_address';
import ReportPersonalInfoMaritalStatus
    from './containers/reporting/subject/personalInfo/report_personalInfo_maritalStatus';

import TaskEmailUpload from './containers/unauth/task_email_upload';
import UserPasswordReset from './containers/unauth/password_reset';
import TaskEmailConfirmSubjectChanges from './containers/unauth/task_email_confirm_subject_changes';
import CloseBrowserScreen from "./containers/unauth/close_browser_screen";
import {configureAxios} from "./actions/axios_config";
import {ScrollToTop} from "./components/utils/scroll_to_top";
import {ReportingSubjectReview} from "./containers/reporting/subject/reporting_subject_review";
import {ReportPersonalInfoCohabitant} from "./containers/reporting/subject/personalInfo/report_personalInfo_cohabitant";
import {ReportPersonalInfoArrest} from "./containers/reporting/subject/personalInfo/report_personalInfo_arrest";
import {ReportInitiateSubjectReview} from "./containers/reporting/subject/personalInfo/report_personalInfo_initiateReview";
import {ReportForeignCitizenship} from "./containers/reporting/subject/foreignTravel/report_foreign_citizenship";
import {ReportForeignBankAccount} from "./containers/reporting/subject/foreignTravel/report_foreign_bank_account";
import {ReportForeignBusinessInvolvement} from "./containers/reporting/subject/foreignTravel/report_foreign_business_involvement";
import {ReportForeignIdCard} from "./containers/reporting/subject/foreignTravel/report_foreign_id_card";
import {ReportForeignProperty} from "./containers/reporting/subject/foreignTravel/report_foreign_property";
import {ReportForeignIntelligenceContact} from "./containers/reporting/subject/foreignTravel/report_foreign_intelligence_contact";
import {ReportForeignAdoptions} from "./containers/reporting/subject/foreignTravel/report_foreign_adoptions";
import {ReportFinancialIssueAnomaly} from "./containers/reporting/subject/report_finanacial_issue_anomaly";
import {ReportMediaContact} from "./containers/reporting/subject/other/report_other_mediaContact";
import {ReportPostForeignTravel} from "./containers/reporting/subject/foreignTravel/post_foreign_travel";
import {ReportAlcoholDrugTreatment} from "./containers/reporting/subject/report_alcohol_drug_treatment";
import {VisitReview} from "./containers/org/dash/visit/visit_review";
import {VisitFix} from "./containers/reporting/subject/visit/visit_fix";
import {ApprovedVisitList} from "./containers/org/dash/visit/visit_list";
import {SubjectTaskReview} from "./containers/org/dash/task/task_subject_review";
import {DocControlList} from "./containers/org/dash/docControl/doc_control_list";
import {DocControlNew} from "./containers/org/dash/docControl/doc_control_new";
import {DocControlUploadList} from "./containers/org/dash/docControl/doc_control_upload_list";
import {AssetDetail} from "./containers/org/dash/asset/asset_detail";
import {DocControlDetail} from "./containers/org/dash/docControl/doc_control_detail";
import {AssetUpdate} from "./containers/org/dash/asset/asset_update";
import {DocControlUpdate} from "./containers/org/dash/docControl/doc_control_update";
import ReportingPersonalInformation from "./containers/reporting/subject/reporting_personal_information_menu";
import ReportingForeignInformation from "./containers/reporting/subject/reporting_foreign_information_menu";
import ReportingPriorInformation from "./containers/reporting/subject/reporting_prior_information_menu";
import {ReportOnOthers} from "./containers/reporting/subject/report_on_others";
import {SubjectTaskReportByOthersReview} from "./containers/org/dash/task/task_reported_by_others_review";
import {ScreeningList} from "./containers/org/dash/screening/screening_list";
import {ScreeningNew} from "./containers/org/dash/screening/screening_new";
import {ScreeningDetail} from "./containers/org/dash/screening/screening_detail";
import {SubjectIssueList} from "./containers/reporting/subject_outstanding_issue_list";
import FaqPage from "./containers/faq";
import {ContractUpload} from "./components/contract/contract_upload";
import {NewHire} from "./containers/org/dash/task/newHire/new_hire_list";
import {SubjectNewHireTaskReview} from "./containers/org/dash/task/newHire/task_newhire_review";
import {NewHireClearanceCheck} from "./containers/org/dash/task/newHire/new_hire_clearance_check_list";
import newTask_Main from "./containers/org/dash/task_new/newTask_Main";
import {TaskEmailForward} from "./containers/unauth/task_email_forward";
import { EntityDashboardTable } from "./containers/org/dash/dashboard/entity_dashboard_table";
import { SubjectTaskRCheckClearanceReview } from "./containers/org/dash/task/task_checkclearance_review";
import { SubjectReportsCheckClearance } from "./containers/org/dash/task/task_reported_check_clearance";
import {FacilityDocList} from './containers/org/dash/dashboard/dash_facility_doc_list'


const Config = require('Config');
const createStoreWithMiddleware = applyMiddleware(thunk, ReduxPromise)(createStore);
export const myStore = createStoreWithMiddleware(reducers);

// Axios
configureAxios();

// Base URL
const baseUrl = Config.routePrefix ? Config.routePrefix : "";

ReactDOM.render(
    <Provider store={myStore}>
        <BrowserRouter basename={baseUrl}>
            <div>
                <ScrollToTop/>
                <TruNav/>
                <div className="container-fluid p-0">
                    <Growler/>
                    <LoadingSpinner />
                    <Switch>
                        <Route path="/login" component={Authenticate}/>
                        <Route path="/org/new" component={Auth(OrgNew)}/>

                        {/* -- Organization Dashboard -- */}
                        <Route path="/org/list" component={Auth(OrgList)} />
                        <Route path="/org/dash/facility/new" component={Auth(OrgDash(FacilityNew))}/>
                        <Route path="/org/dash/facility/:id/update" component={Auth(OrgDash(FacilityUpdate))}/>
                        <Route path="/org/dash/facility/:id" component={Auth(OrgDash(FacilityDetail))}/>
                        <Route path="/org/dash/facility" exact component={Auth(OrgDash(FacilityList))}/>
                        <Route path="/org/dash/dashFacilities/total/:complianceType" exact component={Auth(OrgDash(DashFacilities))}/>
                        <Route path="/org/dash/dashFacilities/byDocType/:docType/:complianceType" exact component={Auth(OrgDash(DashFacilities))}/>
                        <Route path="/org/dash/dashFacilities/byDocType/:docType" exact component={Auth(OrgDash(FacilityDocList))}/>
                        <Route path="/org/dash/doc" exact component={Auth(OrgDash(DocList))}/>
                        <Route path="/org/dash/contract/new/:taskOrderParentContractId" component={Auth(OrgDash(ContractNew))}/>
                        <Route path="/org/dash/contract/new" component={Auth(OrgDash(ContractNew))}/>
                        <Route path="/org/dash/contract/upload" component={Auth(OrgDash(ContractUpload))}/>
                        <Route path="/org/dash/contract/:id/generateChild" component={Auth(OrgDash(ContractNew))}/>
                        <Route path="/org/dash/contract/:id/update" component={Auth(OrgDash(ContractUpdate))}/>
                        <Route path="/org/dash/contract/:id" component={Auth(OrgDash(ContractDetail))}/>
                        <Route path="/org/dash/contract" exact component={Auth(OrgDash(ContractList))}/>
                        <Route path="/org/dash/subject/:id" component={Auth(OrgDash(SubjectDetail))}/>
                        <Route path="/org/dash/subject" exact component={Auth(OrgDash(SubjectList))}/>
                        <Route path="/org/dash/report" exact component={Auth(OrgDash(GenerateReport))}/>
                        <Route path="/org/dash/visits" exact component={Auth(OrgDash(ApprovedVisitList))}/>
                        <Route path="/org/dash/dashSubjects/total/:complianceType" exact component={Auth(OrgDash(DashSubjects))}/>
                        <Route path="/org/dash/dashSubjects/byDocType/:docType/:complianceType" exact component={Auth(OrgDash(DashSubjects))}/>
                        <Route path="/org/dash/subjectUpload/Removal" exact component={Auth(OrgDash(SubjectRemovalList))}/>
                        <Route path="/org/dash/subjectUploadToOrg/:facilityId" exact component={Auth(OrgDash(SubjectUploadList))}/>
                        <Route path="/org/dash/serviceCenter/:id" component={Auth(OrgDash(ServiceCenterOrgDetail))}/>
                        <Route path="/org/dash/serviceCenter" exact component={Auth(OrgDash(ServiceCenterDash))}/>
                        <Route path="/org/dash/user" exact component={Auth(OrgDash(OrgUserList))}/>
                        <Route path="/org/dash/settings/:id/update" exact component={Auth(OrgDash(OrgUpdate))}/>
                        <Route path="/org/dash/settings" exact component={Auth(OrgDash(OrgDetail))}/>
                        <Route path="/org/dash/task/reviewReportsByOthers/:id" exact component={Auth(OrgDash(SubjectTaskReportByOthersReview))} />
                        <Route path="/org/dash/task/reviewCheckClearance/:id" exact component={Auth(OrgDash(SubjectTaskRCheckClearanceReview))} />
                        <Route path="/org/dash/task/reviewCheckClearancebyHR/:id" exact component={Auth(OrgDash(SubjectReportsCheckClearance))} />
                        <Route path="/org/dash/task/review/:id" exact component={Auth(OrgDash(SubjectTaskReview))} />
                        <Route path="/org/dash/task/new" exact component={Auth(OrgDash(newTask_Main))} />
                        <Route path="/org/dash/task/filter/:type" component={Auth(OrgDash(TaskList))} />
                        <Route path="/org/dash/task/:id" component={Auth(OrgDash(TaskDetail))} />
                        <Route path="/org/dash/task" exact component={Auth(OrgDash(TaskList))} />
                        <Route path="/org/dash/newHireTask/review/:id" exact component={Auth(OrgDash(SubjectNewHireTaskReview))} />
                        <Route path="/org/dash/newHireTask/new" exact component={Auth(OrgDash(NewHire))} />
                        <Route path="/org/dash/newHireClearanceCheckTask/new" exact component={Auth(OrgDash(NewHireClearanceCheck))} />
                        <Route path="/org/dash/screening/detail/:id" exact component={Auth(OrgDash(ScreeningDetail))} />
                        <Route path="/org/dash/screening/new" exact component={Auth(OrgDash(ScreeningNew))} />
                        <Route path="/org/dash/screening" exact component={Auth(OrgDash(ScreeningList))} />
                        <Route path="/org/dash/asset/new" exact component={Auth(OrgDash(AssetNew))} />
                        <Route path="/org/dash/asset/uploadToOrg" exact component={Auth(OrgDash(AssetUploadList))} />
                        <Route path="/org/dash/asset/detail/:id" component={Auth(OrgDash(AssetDetail))} />
                        <Route path="/org/dash/asset/:id/update" component={Auth(OrgDash(AssetUpdate))} />
                        <Route path="/org/dash/asset" component={Auth(OrgDash(AssetList))} />
                        <Route path="/org/dash/docControl/new" component={Auth(OrgDash(DocControlNew))} />
                        <Route path="/org/dash/docControl/uploadToOrg" exact component={Auth(OrgDash(DocControlUploadList))} />
                        <Route path="/org/dash/docControl/detail/:id" component={Auth(OrgDash(DocControlDetail))} />
                        <Route path="/org/dash/docControl/:id/update" component={Auth(OrgDash(DocControlUpdate))} />
                        <Route path="/org/dash/docControl" component={Auth(OrgDash(DocControlList))} />
                        <Route path="/org/dash/visitRequest/:visitIssueId" component={Auth(OrgDash(VisitReview))} />
                        <Route path="/org/dash/visitRequests" exact component={Auth(OrgDash(VisitRequestList))} />
                        <Route path="/org/dash" exact component={Auth(OrgDash(OrgDashIndex))}/>
                        <Route path="/org/dash/summary/:entityType" exact component={Auth(OrgDash(EntityDashboardTable))}/>

                        {/* -- System Dashboard -- */}
                        <Route path="/sys/dash/org/:id" component={Auth(SysDash(SysOrgDetail))}/>
                        <Route path="/sys/dash/org" exact component={Auth(SysDash(SysOrgList))}/>
                        <Route path="/sys/dash/user" exact component={Auth(SysDash(SysUserList))}/>
                        <Route path="/sys/dash/subject/duplicateSubjects" exact component={Auth(SysDash(SysSubjectMergeList))} />
                        <Route path="/sys/dash/signaturePurchase" exact component={Auth(SysDash(PurchaseList))}/>
                        <Route path="/sys/dash" exact component={Auth(SysDash(SysDashIndex))}/>
                        <Route path="/sys/dash/task/new" component={Auth(SysDash(SysTaskNew))} />
                        <Route path="/sys/dash/task/filter/:type" component={Auth(SysDash(SysTaskList))} />
                        <Route path="/sys/dash/task/:id" component={Auth(SysDash(SysTaskDetail))} />
                        <Route path="/sys/dash/task" exact component={Auth(SysDash(SysTaskList))} />

                        {/* -- Account -- */}
                        <Route path="/account/profile" exact component={Auth(UserProfile)}/>

                        {/* -- Reporting -- */}
                        <Route path="/reporting/subject/foreignTravel/main" exact component={ReportingAuth(ReportForeignTravel)} />
                        <Route path="/reporting/subject/foreignTravel/edit/:foreignTravelId" component={ReportingAuth(ReportForeignTravel)} />
                        <Route path="/reporting/subject/foreignTravel/contacts" exact component={ReportingAuth(ReportForeignContacts)} />
                        <Route path="/reporting/subject/foreignTravel/intelligenceContact" exact component={ReportingAuth(ReportForeignIntelligenceContact)} />
                        <Route path="/reporting/subject/foreignTravel/voting" exact component={ReportingAuth(ReportForeignVoting)} />
                        <Route path="/reporting/subject/foreignTravel/citizenship" exact component={ReportingAuth(ReportForeignCitizenship)} />
                        <Route path="/reporting/subject/foreignTravel/bankAccount" exact component={ReportingAuth(ReportForeignBankAccount)} />
                        <Route path="/reporting/subject/foreignTravel/businessInvolvement" exact component={ReportingAuth(ReportForeignBusinessInvolvement)} />
                        <Route path="/reporting/subject/foreignTravel/idCard" exact component={ReportingAuth(ReportForeignIdCard)} />
                        <Route path="/reporting/subject/foreignTravel/property" exact component={ReportingAuth(ReportForeignProperty)} />
                        <Route path="/reporting/subject/foreignTravel/adoption" exact component={ReportingAuth(ReportForeignAdoptions)} />
                        <Route path="/reporting/subject/foreignTravel/blackmail" exact component={ReportingAuth(ReportBlackmail)} />
                        <Route path="/reporting/subject/financialIssueAnomaly" exact component={ReportingAuth(ReportFinancialIssueAnomaly)} />
                        <Route path="/reporting/subject/reportOnOthers" exact component={ReportingAuth(ReportOnOthers)} />
                        <Route path="/reporting/subject/alcoholDrugTreatment" exact component={ReportingAuth(ReportAlcoholDrugTreatment)} />
                        <Route path="/reporting/subject/personalInfo/address" exact component={ReportingAuth(ReportPersonalInfoAddress)} />
                        <Route path="/reporting/subject/personalInfo/maritalStatus" exact component={ReportingAuth(ReportPersonalInfoMaritalStatus)} />
                        <Route path="/reporting/subject/personalInfo/cohabitant" exact component={ReportingAuth(ReportPersonalInfoCohabitant)} />
                        <Route path="/reporting/subject/personalInfo/arrest" exact component={ReportingAuth(ReportPersonalInfoArrest)} />
                        <Route path="/reporting/subject/other/mediaContact" exact component={ReportingAuth(ReportMediaContact)} />
                        <Route path="/reporting/subject/personalInfo/initiateReview" exact component={ReportingAuth(ReportInitiateSubjectReview)} />
                        <Route path="/reporting/subject/periodicReview/:hashCode" component={ReportingSubjectReview} />
                        <Route path="/reporting/subject/foreignTravel/postTravel/:emailCode" component={ReportPostForeignTravel} />
                        <Route path="/reporting/subject/emailCheck/passCodeCheck" component={ReportSecondFactor} />
                        <Route path="/reporting/subject/emailCheck" component={ReportEmailVerify} />
                        <Route path="/reporting/subject/fixVisits/:emailCode" exact component={VisitFix} />
                        <Route path="/reporting/subject/visits" exact component={ReportingAuth(VisitList)} />
                        <Route path="/reporting/subject/reportingPersonalInformation" exact  component={ReportingPersonalInformation} />
                        <Route path="/reporting/subject/reportingForeignInformation" exact  component={ReportingForeignInformation} />
                        <Route path="/reporting/subject/reportingPriorInformation" exact  component={ReportingAuth(ReportingPriorInformation)} />
                        <Route path="/reporting/subject/taskList" exact  component={ReportingAuth(SubjectIssueList)} />
                        <Route path="/reporting/subject/taskList/:type" exact  component={ReportingAuth(SubjectIssueList)} />
                        <Route path="/reporting/subject" component={ReportingAuth(ReportingSubjectMenu)} />


                        {/* -- Other -- */}
                        <Route path="/task/email/uploadDoc/:emailCode" component={TaskEmailForward} />
                        <Route path="/task/email/uploadDocDirectly/:emailCode" component={TaskEmailUpload} />
                        <Route path="/task/user/resetPassword/:emailCode" component={UserPasswordReset} />
                        <Route path="/task/email/subjectChanges/confirm/:emailCode/:changeType" component={TaskEmailConfirmSubjectChanges} />
                        <Route path="/misc/contactUs" component={ContactUsForm} />
                        <Route path="/misc/closeBrowser" component={CloseBrowserScreen} />
                        <Route path="/faq" component={FaqPage}/>

                        <Route exact path="/" component={Main}/>
                        <Route exact path={`${Config.routePrefix}`} component={Main}/>
                        <Route component={PageNotFound}/>
                    </Switch>
                </div>
            </div>
        </BrowserRouter>
    </Provider>
    , document.querySelector("#container")
);