/**
 * Created by shivaraman on 5/10/17.
 */
import {
    CREATE_PERSON,
    CREATE_PERSONS,
    CSV_UPLOAD,
    FETCH_PERSON_BY_ID,
    FETCH_PERSONS,
    FETCH_SUBJECT_REPORTABLES, FETCHED_PERSONS_USER_CREATION,
    INVALID_TOKEN,
    LOGOUT_SUCCESS,
    SEARCH_PERSONS,
    UPDATE_PERSON
} from '../actions/action_constants';
import {getSubjectObj} from "../actions/subject_actions";

export function reducePersons(state = [], action) {
    switch (action.type) {
        case FETCH_PERSONS:
            return action.payload.data; // data is an axios specific. Whatever response we get is in data under payload or whatever is returned in action
        case INVALID_TOKEN:
        case CREATE_PERSONS:
            return state;
        case UPDATE_PERSON:
            return {
                subjects: []
            };
        case LOGOUT_SUCCESS:
            state = undefined;
            return state;
    }
    return state;
}

export function reducePersonsForUserCreation(state = [], action){
    switch(action.type){
        case FETCHED_PERSONS_USER_CREATION:
            return action.payload.data;
    }
    return state;
}
export function reducePersonSearchResults(state = [], action) {
    switch (action.type) {
        case SEARCH_PERSONS:
            return action.payload.data.map(getSubjectObj);
    }
    return state;
}

export function reducePersonAndFacilities(state = [], action) {
    switch (action.type) {
        case CSV_UPLOAD:
            return action.payload;
    }
    return state;
}

export function reducePerson(state = {}, action) {
    switch (action.type) {
        case FETCH_PERSON_BY_ID:
        case CREATE_PERSON:
            return getSubjectObj(action.payload.data);
    }
    return state;
}

export function reduceSubjectReportables(state = [], action){
    switch (action.type){
        case FETCH_SUBJECT_REPORTABLES:
            return action.payload.data;
    }
    return state;
}