/**
 * Created by pshivaraman on 3/23/18.
 */

import {
    DELETE_ORG,
    DELETE_ORG_DOC,
    FETCH_CUR_ORG,
    FETCH_ORG,
    FETCH_ORG_DOCS,
    FETCH_ORGS,
    FETCH_ORGS_BY_USER, FETCH_ORGS_SEARCH,
    UPLOAD_ORG_DOCS
} from "../actions/action_constants";
import {getOrgObj} from "../actions/org_actions";

export function reduceOrgs(state = [], action) {
    switch (action.type) {
        case FETCH_ORGS:
        case DELETE_ORG:
        case FETCH_ORGS_BY_USER:
            return action.payload.data.map(getOrgObj); // data is an axios specific. Whatever response we get is in data under payload or whatever is returned in action
    }
    return state;
}

export function reduceOrgsSearch(state = [], action){
    switch (action.type){
        case FETCH_ORGS_SEARCH:
            return action.payload.data.map(getOrgObj);
    }

    return state;
}

export function reduceOrg(state = [], action) {
    switch (action.type) {
        case FETCH_ORG:
            return getOrgObj(action.payload.data);
    }
    return state;
}

export function reduceCurOrg(state = [], action) {
    switch (action.type) {
        case FETCH_CUR_ORG:
            return getOrgObj(action.payload.data);
    }
    return state;
}

export function reduceOrgDocuments(state = [], action) {
    switch (action.type) {
        case FETCH_ORG_DOCS:
            return action.payload.data;
        case UPLOAD_ORG_DOCS:
            return action.payload.data.documents;
        case DELETE_ORG_DOC:
            return action.payload.data.documents;
    }
    return state;
}