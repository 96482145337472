import {combineReducers} from "redux";
import {
    reducePerson,
    reducePersonAndFacilities,
    reducePersons,
    reducePersonSearchResults,
    reducePersonsForUserCreation,
    reduceSubjectReportables
} from "./reducer_subjects";
import {reducer as formReducer} from "redux-form";
import {reduceUser, reduceUsers} from "./reducer_user";
import {reduceFacilities, reduceFacilitySearchResults, reduceOrgFacility, reduceSmoDocuments} from "./reducer_facility";
import {reduceAgencies} from "./reducer_agency";
import {reduceCurOrg, reduceOrg, reduceOrgDocuments, reduceOrgs, reduceOrgsSearch} from "./reducer_org";
import {reduceDoc, reduceDocResults, reduceDocs} from "./reducer_doc";
import {reduceMsgs} from "./reducer_msgs";
import {reduceOrgContract, reduceOrgContracts} from "./reducer_contract";
import {reduceTask, reduceTasks} from "./reducer_task";
import {reduceCompliantEntities} from "./reducer_compliant_entities";
import {reduceForRemoval} from "./reducer_subjects_for_removal";
import {reduceReportableActivity} from "./reducer_reportableActivity";
import {reduceManagedOrgs} from "./reducer_managed_org";
import {reduceDocsAndStatus} from "./reducer_doc_status";
import {reduceVisitIssue, reduceVisitIssues} from "./reducer_visit_issues";
import {reduceAssets, reduceDocControls} from "./reducer_assets";
import {reduceSubjectForSelfReporting} from "./reducer_subject_for_self_reportingsubjects";

const rootReducer = combineReducers({
    org: reduceOrg,
    cur_org: reduceCurOrg,
    orgs: reduceOrgs,
    orgs_search: reduceOrgsSearch,
    managed_orgs: reduceManagedOrgs,
    org_docs: reduceOrgDocuments,
    org_contracts: reduceOrgContracts,
    org_contract: reduceOrgContract,
    org_facility: reduceOrgFacility,
    agencies: reduceAgencies,
    docs: reduceDocs,
    docsAndStatus: reduceDocsAndStatus,
    doc_results: reduceDocResults,
    doc: reduceDoc,
    subjects: reducePersons,
    assetsUploaded: reduceAssets,
    docControlsUploaded: reduceDocControls,
    subjects_for_removal: reduceForRemoval,
    subjects_for_usercreation: reducePersonsForUserCreation,
    subject_results: reducePersonSearchResults,
    subject_reportables: reduceSubjectReportables,
    subject_for_self_reporting: reduceSubjectForSelfReporting,
    facility_results: reduceFacilitySearchResults,
    subjectAndFacilities: reducePersonAndFacilities,
    person: reducePerson,
    smos: reduceFacilities,
    compliantEntityStatus: reduceCompliantEntities,
    smo_docs: reduceSmoDocuments,
    task: reduceTask,
    tasks: reduceTasks,
    form: formReducer,
    user: reduceUser,
    reportableActivity: reduceReportableActivity,
    users: reduceUsers,
    msgs: reduceMsgs,
    visit_issues: reduceVisitIssues,
    visit_issue: reduceVisitIssue
});

export default rootReducer;
