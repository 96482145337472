import {VISIT_ISSUE_FETCHED, VISIT_ISSUES_FETCHED} from "../actions/action_constants";

export function reduceVisitIssues(state = null, action) {
    switch (action.type) {
        case VISIT_ISSUES_FETCHED:
            return action.payload.data;
    }
    return state;
}

export function reduceVisitIssue(state = null, action) {
    switch (action.type) {
        case VISIT_ISSUE_FETCHED:
            return action.payload.data;
    }
    return state;
}
