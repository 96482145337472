/**
 * Created by shivaraman on 5/10/17.
 */
import {FETCH_USER_BY_PERSON, FETCH_USERS} from '../actions/action_constants';

export function reduceUser(state = [], action) {
    switch (action.type) {
        case FETCH_USER_BY_PERSON:
            return action.payload.data; // data is an axios specific. Whatever response we get is in data under payload or whatever is returned in action
    }
    return state;
}

export function reduceUsers(state = [], action) {
    switch (action.type) {
        case FETCH_USERS:
            return action.payload.data;
    }
    return state;
}